/* You can add global styles to this file, and also import curriculum-vitae style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
}

.grid {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.w-100 {
  width: 100%;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.info-background {
  background: #8cd5f7;
  padding: 5px;
}

.pointer {
  cursor: pointer;
}

.gap-4 {
  gap: 16px;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.6;
}

.p-inputnumber {
  width: 100%;
}

ol.k {list-style-type: lower-alpha;}

.cursor-none{cursor:not-allowed;}

.p-column-filter-buttonbar .p-button {
  margin-right: 10px !important;
}
